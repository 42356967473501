<template>
  <div class="row-fluid csn-game-play">
    <div class="row container-game-play" v-if="IS_GAME_DEMO_PENDING">
      <Loader />
    </div>

    <div :class="gameContainerClassList" v-else-if="existsGame">
      <div v-if="hasTimer" class="csn-game-play-timer-container">
        <Time />
      </div>
      <iframe v-if="hasIframe" :src="game.responseIframe"></iframe>
      <Message :message="t('no_iframe')" :type="msgType" v-else />
      <!-- <div class="col-md-12">
        <p class="alert alert-danger">{{ message }}</p>
      </div>
       -->

      <div class="option-buttons csn-option-buttons">
        <ul>
          <li>
            <a
              href="#"
              class="btn casino-btn casino-btn-default casino-buttons"
              @click="toggleFullScreenMode"
            >
              <span class="casino-icon casino-icon-full-size"></span>
            </a>
          </li>
          <!-- <li>
            <Route
              :to="{ name: gameRouteName }"
              class="btn casino-btn casino-btn-default casino-buttons"
              @click.native="handleGameListButtonClick"
            >
              <span class="casino-icon casino-icon-games"></span>
            </Route>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  Module,
  GAME_DEMO_DICTIONARY,
  IS_GAME_DEMO_PENDING,
  GAME_DEMO,
} from '@/constants'
import { isNotNil } from '@/helpers'
import { gameMixin } from '@/mixins'

export default {
  name: GAME_DEMO,
  components: {
    Loader: () => import('@/components/Loader'),
    Message: () => import('@/components/atoms/Message'),
    Time: () => import('@/components/Time'),
    // Route: () => import('@/components/Route'),
  },
  mixins: [gameMixin],
  computed: {
    ...mapState(Module.GAME_DEMO, [GAME_DEMO_DICTIONARY, IS_GAME_DEMO_PENDING]),
    game() {
      return this.GAME_DEMO_DICTIONARY?.[this.gameSlug]
    },
    existsGame() {
      return isNotNil(this.game)
    },
    hasIframe() {
      return !!this.game?.responseIframe
    },
    hasTimer() {
      return this.game.realClock
    },
  },
}
</script>
